"use strict";
/* ========================================================================
 * DOM-based Routing
 * Based on https://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      Dropdown.init();
      TabNav.init();
    },
    isEmpty: function (str) {
      return (!str || (/^\s*$/).test(str));
    }
  };
  var Navbar = {
    init: function () {
      /*if (window.matchMedia("(max-width: 991px)").matches) {*/
      var navBarPoint = $('#navbar-line').waypoint({
        handler: function (direction) {
          if (direction === "down") {
            $('#fixed-top').addClass('navbar-fixed-top');
            $('#back-to-top').addClass('btt-visible');
          } else if (direction === "up") {
            $('#fixed-top').removeClass('navbar-fixed-top');
            $('#back-to-top').removeClass('btt-visible');
          }
        }
      });
      /*}*/
      var scrollToTop = $('#scroll-line').waypoint({
        handler: function (direction) {
          if (direction === "down") {
            $('#back-to-top').addClass('btt-fixed');
          } else if (direction === "up") {
            $('#back-to-top').removeClass('btt-fixed');
          }
        }
      });
    }
  };
  var Dropdown = {
    init: function () {
      $('#citta-dropdown-menu a').on('click', function (event) {
        $('#citta-select').html($(this).html() + '<span class="caret"></span>');
      });
    }
  };
  var Readmore = {
    init: function () {
      $.map($('.is-readmore'), function (el, elIndex) {
        var textEl;
        var $textHt = $(el).data("height");
        if (!$textHt) {
          $textHt = 100;
        }
        textEl = el.textContent;
        $(el).shave($textHt);
        if ($(el).find('.js-shave').length > 0) {
          if ($(el).next().length === 0) {
            $(el).after('<a class="expand" href="#" aria-expanded="false" aria-controls="content"><i class="fa fa-chevron-down" aria-hidden="true"></i>Leggi di Più</a>');
            var next = $(el).next();
            // FIXME: Ovviamente se a.expand non è più l'elemento successivo, smette di funzionare. Rivedere le stringhe per la localizzazione del readmore
            $(next).on('click', function (event) {
              event.preventDefault();
              if ($(el).find('.js-shave').length > 0) {
                el.textContent = textEl;
                next.html('<a class="expand" href="#" aria-expanded="false" aria-controls="content"><i class="fa fa-chevron-up" aria-hidden="true"></i> Chiudi</a>');
              } else {
                $(el).shave(100);
                next.html('<a class="expand" href="#" aria-expanded="true" aria-controls="content"><i class="fa fa-chevron-down" aria-hidden="true"></i> Leggi di Più</a>');
              }
            });
          }
        }
      });
    },
    refresh: function () {
      Readmore.init();
    }
  };
  var Meta = {
    isBar: false,
    refresh: function () {
      if (window.matchMedia("(max-width: 991px)").matches) {
        $('#sidebar-wrapper').appendTo('#metabar-container');
        this.isBar = true;
        return;
      } else if (window.matchMedia("(min-width: 992px)").matches && this.isBar === true) {
        $('#sidebar-wrapper').appendTo('#sidebar-container');
        this.isBar = false;
        return;
      }
    }
  };
  var Select2 = {
    init: function() {
      $('.form-select2').select2();
    }
  };
  var SelectPicker = {
    init: function () {
      $('.selectpicker').selectpicker({
        'style': 'btn-meta-bar'
      });
      $('.selectpicker-search').selectpicker({
        'style': 'btn-default btn-dropdown ricerca__button ricerca__button--selezione'
      });
    }
  };
  var DatePicker = {
    init: function () {
      var months = 3;
      if (window.matchMedia("(max-width: 768px)").matches) {
        months = 1;
      } else if (window.matchMedia("(max-width: 1200px)").matches) {
        months = 2;
      }
      $(".datepicker input, .input-daterange").datepicker();
      $('.datepicker.datepicker--calendario').datepicker({
        numberOfMonths: months,
        showButtonPanel: true,
        onSelect: function (dateText, el) {
          $('#calendario-modal').modal('toggle');
        }
      });
      $('.datepicker.datepicker--registrazione').datepicker();
    },
    refresh: function () {
      $('.datepicker.datepicker--calendario').datepicker('destroy');
      DatePicker.init();
    }
  };
  var Accordion = {
    init: function () {
      $('.collapse').on('shown.bs.collapse', function () {
        RESIZE.fire();
      });
      //FIXME: Questo è un esempio di come tenere aperto l'accordion
      // itinerario su luoghi. Va adattato sulla base dell'id adottato nel file nunjucks.
      $('#accordion-indicazioniData').collapse('show');
    }
  };

  /* FIXME: Usare un sistema meno brutale per assegnare i valori del range */
  var Range = {
    init: function () {
      if ($(".slider-range--ui").length) {
        $('#slider-range__label--from').text($(".slider-range").data("from"));
        $('#slider-range__label--to').text($(".slider-range").data("to"));
        $(".slider-range").slider({
          range: true,
          min: $(".slider-range").data("from"),
          max: $(".slider-range").data("to"),
          values: [$(".slider-range").data("from"), $(".slider-range").data("to")],
          slide: function (event, ui) {
            $('#slider-range__label--from').text(ui.values[0]);
            $('#slider-range__label--to').text(ui.values[1]);
          }
        });
      }
      if ($(".slider-range--bs").length) {
        var sB = $(".slider-range--bs").slider();
        sB.on('slide', function (event) {
          console.log(event.value);
          $('.slider-tick-label:first-child').text("da " + event.value[0] + "€");
          $('.slider-tick-label:last-child').text("a " + event.value[1] + "€");
        }).data('slider');

      }
    }
  };

  var Table = {
    //FIXME: La formattazione dei contenuti è un orrore ma non posso fare molto diversamente.
    init: function () {
      if ($('#tabella-lista').length) {
        $('#tabella-lista').DataTable({
          info: false,
          paging: false,
          searching: false,
          responsive: true,
          fixedHeader: true,
          createdRow: function (row, data, dataIndex) {
            $(row).addClass("tabella__colonna tabella__colonna--" + data.type);
          },
          columns: [{
            data: "nome",
            render: function (data, type, row, meta) {
              if (type === 'display') {
                var currentData = data;
                if (data.length > 32) {
                  data = data.substr(0, 32) + '…';
                }
                if (!UTIL.isEmpty(row.urls.edizione)) {
                  data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                }
              }
              return data;
            }
          },
            {
              data: "categoria_father_it",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  if (!UTIL.isEmpty(row.urls.cat_corsi)) {
                    data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                  }
                }
                return data;
              }
            },
            {
              data: "categoria_it",
              render: function (data, type, row, meta) {
                var currentData = "";
                if (type === 'display') {
                  currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                }
                return '<span title="' + currentData + '">' + data + '</span>';
              }
            },
            {
              data: "comune",
              render: function (data, type, row, meta) {
                var currentData = "";
                if (type === 'display') {
                  currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                }
                return '<span title="' + currentData + '">' + data + '</span>';
              }
            },
            {
              data: "luogo",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  if (!UTIL.isEmpty(row.urls.luogo)) {
                    data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                  }
                }
                return data;
              }
            },
            {
              data: "data_inizio",
              render: function (data, type, row) {
                var df = row.data_fine;
                var startDate = moment(data, moment.ISO_8601).format("DD/MM/YYYY");
                var endDate = moment(df, moment.ISO_8601).format("DD/MM/YYYY");
                return "Dal " + startDate + " al " + endDate;
              }
            },
            {
              data: "prezzo",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  //if (!UTIL.isEmpty(row.urls.prezzo)) {
                  data = '<span="tabella__colonna--prezzo" >' + currentData + '</span>' + '<s class="tabella__colonna--sconto">€ 150</s>';
                  //}
                }
                return data;
              }
            },
          ],
          processing: true,
          ajax: {
            "url": "https:///EsempioRispostaRestService.json",
            "dataSrc": "resultItem",
            "type": "GET"
          }
        });
      }

      if ($('#tabella-luoghi').length) {
        $('#tabella-luoghi').DataTable({
          info: false,
          paging: false,
          searching: false,
          responsive: true,
          createdRow: function (row, data, dataIndex) {
            $(row).addClass("tabella__colonna tabella__colonna--" + data.type);
          },
          columns: [
            {
              data: "data_inizio",
              render: function (data, type, row) {
                var df = row.data_fine;
                var startDate = moment(data, moment.ISO_8601).format("DD/MM/YYYY");
                var endDate = moment(df, moment.ISO_8601).format("DD/MM/YYYY");
                return "Dal " + startDate + " al " + endDate;
              }
            },
            {
              data: "nome",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  if (!UTIL.isEmpty(row.urls.edizione)) {
                    data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                  }
                }
                return data;
              }
            },
            {
              data: "categoria_father_it",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  if (!UTIL.isEmpty(row.urls.cat_corsi)) {
                    data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                  }
                }
                return data;
              }
            },
            {
              data: "luogo",
              render: function (data, type, row, meta) {
                if (type === 'display') {
                  var currentData = data;
                  if (data.length > 32) {
                    data = data.substr(0, 32) + '…';
                  }
                  if (!UTIL.isEmpty(row.urls.luogo)) {
                    data = '<a title="' + currentData + '"href="' + row.urls.luogo + '">' + data + '</a>';
                  }
                }
                return data;
              }
            }
          ],
          processing: true,
          ajax: {
            "url": "https:///EsempioRispostaRestService.json",
            "dataSrc": "resultItem",
            "type": "GET"
          }
        });
      }
    }
  };

  var Animation = {
    init: function() {
      AOS.init();

      $('.slide__scrollicon').on('click', function(e) {
        $('html, body').animate({
          scrollTop: $(window).height()
        }, 1200);
      });
    }
  };

  var Card = {
    init: function () {
      var shuffleContainer = new window.Shuffle(document.getElementById('shufflejs-scheda'), {
        itemSelector: '.scheda__wrapper'
      });
    }
  };

  var CountAnim = {
    init: function () {
      $.map($('.counter__value'), function (item, index) {
        var $countValue = $(item).data('count');
        var countAnimation = new CountUp(item, 0, $countValue, 0, 4);
        if (!countAnimation.error) {
          countAnimation.start();
        }
      });
    }
  };

  var Reviews = {
    init: function () {
      if ($('.review').length) {
        $('.review').barrating({
          theme: 'fontawesome-stars'
        });
      }
      if ($('.review--readonly').length) {
        $('.review--readonly').barrating({
          readonly: true
        });
      }
    }
  };

  var Localization = {
    init: function () {
      moment.locale('it');
    }
  };

  var Slideshow = {
    init: function () {
      if ($('.slide__content--testo').length) {
        $('.slide__content--testo').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1800',
          autoplaySpeed: '3600',
          autoplay: false,
          appendDots: '.slide__dots--testo',
          appendArrows: '.slide__arrows--testo',
          prevArrow: '<i class="fa fa-2x fa-chevron-left" aria-hidden="true"></i>',
          nextArrow: '<i class="fa fa-2x fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 1250,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }]
        });
      }

      $('.slide__content--single').each(function () {
        var $el = $(this);
        $el.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          pauseOnDotsHover: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '800',
          fade: true,
          autoplaySpeed: '3600',
          autoplay: false,
          appendDots: $el.siblings('.slide__dots'),
          appendArrows: $el.siblings('.slide__arrows'),
          prevArrow: '<i class="fa fa-2x fa-chevron-left" aria-hidden="true"></i>',
          nextArrow: '<i class="fa fa-2x fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 1250,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }]
        });
      });


      if ($('.slide__content--redesign').length) {
        $('.slide__content--redesign').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          autoplay: true,
          dots: false,
          autoplaySpeed: 7000,
          cssEase: 'ease-in-out',
          lazyLoad: 'progressive',
          asNavFor: '.slide__nav--redesign',
          speed: 500,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                dots: true
              }
            }
          ]
        });

        $('.slide__nav--redesign').slick({
          infinite: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          asNavFor: '.slide__content--redesign',
          dots: false,
          centerMode: false,
          focusOnSelect: true,
          autoplay: true,
          autoplaySpeed: 7000,
          speed: 500
        });
      }

      if ($('.slide__content--home').length) {
        $('.slide__content--home').slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: true,
          appendDots: '.slide__dots.slide__dots--home',
          appendArrows: '.slide__arrows--home',
          prevArrow: '<i class="fa fa-2x fa-chevron-left" aria-hidden="true"></i>',
          nextArrow: '<i class="fa fa-2x fa-chevron-right" aria-hidden="true"></i>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                infinite: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                infinite: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1250,
              settings: {
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },]
        });
      }

      $('.slide__content--carousel').each(function () {
        var $el = $(this);
        $el.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: false,
          appendDots: $el.next().find('.slide__dots'),
          appendArrows: $el.next().find('.slide__arrows'),
          prevArrow: '<button class="slide-arrow slide-arrow--slideshow prev-arrow"><i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button class="slide-arrow slide-arrow--slideshow next-arrow"><i class="fa fa-fw fa-chevron-right" aria-hidden="true"></i></button>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 992,
              settings: {
                arrows: false,
                infinite: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                infinite: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1250,
              settings: {
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },]
        });
      });

      $('.slide__content--correlati').each(function () {
        var $el = $(this);
        $el.slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: false,
          appendDots: $el.next().find('.slide__dots'),
          appendArrows: $el.next().find('.slide__arrows'),
          prevArrow: '<button class="slide-arrow slide-arrow--slideshow prev-arrow"><i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button class="slide-arrow slide-arrow--slideshow next-arrow"><i class="fa fa-fw fa-chevron-right" aria-hidden="true"></i></button>',
          responsive: [
            {
              breakpoint: 9999,
              settings: 'unslick'
            },
            {
              breakpoint: 1250,
              settings: {
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }
          ]
        });
      });

      $('.slide__content--archivio').each(function () {
        var $el = $(this);
        $el.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          appendDots: $el.next().find('.slide__dots'),
          appendArrows: $el.next().find('.slide__arrows'),
          prevArrow: '<button class="slide-arrow slide-arrow--slideshow prev-arrow"><i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button class="slide-arrow slide-arrow--slideshow next-arrow"><i class="fa fa-fw fa-chevron-right" aria-hidden="true"></i></button>',
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: false,
          responsive: [
            {
              breakpoint: 577,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 1200,
              settings: {
                rows: 1,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 9999,
              settings: {
                rows: 1,
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: true
              }
            }
          ]
        });
      });

      $('.slide__content--lista').each(function () {
        var $el = $(this);
        $el.slick({
          appendDots: $el.next().find('.slide__dots'),
          appendArrows: $el.next().find('.slide__arrows'),
          prevArrow: '<button class="slide-arrow slide-arrow--slideshow prev-arrow"><i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button class="slide-arrow slide-arrow--slideshow next-arrow"><i class="fa fa-fw fa-chevron-right" aria-hidden="true"></i></button>',
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: false,
          slidesToShow: 6,
          slidesToScroll: 6,
          rows: 5,
          responsive: [
            {
              breakpoint: 577,
              settings: {
                rows: 6,
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true
              }
            },
          ]
        });
      });

      $('.slide__content--tabs').each(function () {
        var $el = $(this);
        $el.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          prevArrow: '<button class="slide-arrow slide-arrow--slideshow prev-arrow"><i class="fa fa-fw fa-chevron-left" aria-hidden="true"></i></button>',
          nextArrow: '<button class="slide-arrow slide-arrow--slideshow next-arrow"><i class="fa fa-fw fa-chevron-right" aria-hidden="true"></i></button>',
          dots: true,
          cssEase: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
          speed: '1000',
          autoplaySpeed: '1600',
          autoplay: false,
          appendDots: $el.next().find('.slide__dots'),
          appendArrows: $el.next().find('.slide__arrows'),
          responsive: [
            {
              breakpoint: 577,
              settings: {
                rows: 1,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 768,
              settings: {
                rows: 1,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 1200,
              settings: {
                rows: 1,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 9999,
              settings: {
                rows: 1,
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: true
              }
            }
          ]
        });
      });

      $('#slick-single').each(function () {
        $('#slick-single').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        });
      });

      $('.nav-tabs--slideshow a').each(function () {
        var $el = $(this);
        $el.click(function (e) {
          e.preventDefault();
          $(this).tab('show');
        });
      });

      $('.nav-tabs--slideshow a').on('shown.bs.tab', function (e) {
        var $currentTargetID = $(this).attr("href");
        var $currentTarget = $($currentTargetID).find('.slide__content--carousel');
        $($currentTarget).slick('resize');
        $($currentTarget).slick('refresh');
        Ellipsis.init();
      });
    },
    refresh: function () {
    }
  };
  var Ellipsis = {
    init: function () {
      $.map($('.ellipsis'), function (item, index) {
        var $shaveSize = $(item).data('ellipsis');
        $(item).shave($shaveSize); //FIXME: È possibile che sia una operazione abbastanza intensiva in termini di memoria e cpu, ma non mi viene in mente un approccio più sicuro.
        //MatchHeight.refresh();
      });
    },
    refresh: function () {
      Ellipsis.init(); //FIXME: Vedere se è possibile filtrare in qualche modo il numero di richieste necessarie.
    }
  };
  var gMap = {
    // FIXME: Questo è un sistema molto, molto, molto, molto lento. E consuma altrettanta ram. Riscrivere in maniera più performante.
    options: {
      zoom: 9,
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    },
    init: function () {
      var $gmaps = $('body').find('.google-map');
      $($gmaps).map(function (index, el) {
        var $this = $(el);
        var $suffix = $this.data('mapdata');
        var bounds = new google.maps.LatLngBounds();
        var map = new google.maps.Map($this[0], gMap.options);
        map.markers = [];

        var $points = $.map($('#' + $suffix).children(), function (el, index) {
          var $point = $(el).data('coords');
          var latLng = new google.maps.LatLng($point.latitudine, $point.longitudine);
          bounds.extend(latLng);

          var marker = new google.maps.Marker({
            position: latLng,
            map: map
          });
          google.maps.event.addListener(marker, 'click', function () {
            window.location.href = $point.url;
          });
          map.markers.push(marker);
          map.fitBounds(bounds);
        });
      });
    }
  };
  var FilterBar = {
    stored: { //FIXME: La prossima volta vediamo di usare Vue, Angular, Redux o qualunque altra cosa che gestisca lo stato degli elementi.
    },
    init: function () {
      if ($('#sidebar-wrapper').length) {
        var $checkArray = $('#sidebar-wrapper').find('.item-check');
        $.map($checkArray, function (el, index) {
          $(el).change(function (e) {
            e.preventDefault();
          });
        });
      }
    },
    post: function () {
    }
  };
  var uploadFile = {
    /* Qui va inserito il path della gestione dell'upload lato server */
    url: '',
    init: function () {
      if ($('#profilo-avatar-upload').length) {
        $('#profilo-avatar-upload').fileupload({
          url: uploadFile.url,
          dataType: 'json',
          done: function (e, data) {
            $.each(data.result.files, function (index, file) {
              $('<p/>').text(file.name).appendTo('#profilo-avatar-file');
            });
          }
        }).prop('disabled', !$.support.fileInput)
          .parent().addClass($.support.fileInput ? undefined : 'disabled');
      }
    }
  };
  var jsTree = {
    init: function () {
      var $trees = $('body').find('.form__tree');
      $($trees).map(function (index, el) {
        $(el).jstree({
          'plugins': ['checkbox'],
          'core': {
            'themes': {
              'responsive': true
            },
            'data': [
              {
                'text': 'Titolo Macrocategoria',
                'state': {
                  'opened': true,
                  'selected': true
                },
                'children': [
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                ]
              },
              {
                'text': 'Titolo Macrocategoria',
                'children': [
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                ]
              },
              {
                'text': 'Titolo Macrocategoria',

                'children': [
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                ]
              },
              {
                'text': 'Titolo Macrocategoria',
                'children': [
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                  {'text': 'sottocategoria'},
                ]
              }
            ]
          }
        });
      });
    }
  };
  var TabNav = {
    init: function () {
      $('.nav-tabs--slideshow').on('shown.bs.tab', function (e) {
        $('.slide__content--tabs').slick('refresh');
        $('.slide__content--tabs').slick('resize');
        Ellipsis.init();
      });
    }
  };
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        Localization.init();
        Table.init();
        DatePicker.init();
        Range.init();
        Accordion.init();
        Localization.init();
        Reviews.init();
        Readmore.init();
        gMap.init();
        Slideshow.init();
        Ellipsis.init();
        SelectPicker.init();
        Select2.init();
        Meta.refresh();
        FilterBar.init();
        uploadFile.init();
        jsTree.init();
        Navbar.init();
        WAYPOINT.init();
        RESIZE.init();
        Animation.init();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };
  $(window).resize(function () {
    RESIZE.fire();
  });
  // Queste funzioni vengono richiamate ad ogni resize della pagina. Utile per ricalcolare le dimensioni di elementi gestiti da JS.
  var RESIZE = {
    init: function () {

    },
    fire: function () {
      Ellipsis.refresh();
      Slideshow.refresh();
      Meta.refresh();
      Readmore.refresh();
      DatePicker.refresh();
      Select2.init();
      Navbar.init();
      $.fn.matchHeight._update();
    }
  };
  var REQUEST = {
    desktopInit: function () {
      $('input.input-check').change(function () {
        REQUEST.fire();
      });
    },
    mobileInit: function () {
      $('#sidebar-request').on('click', function () {
        REQUEST.fire();
      });
    },
    fire: function () {

    }
  };
  // Queste funzioni vengono richiamate con la visualizzazione a schermo di determinati elementi. FIXME: Assegnare dinamicamente quale funzione eseguire.
  var WAYPOINT = {
    init: function () {
      var $waypoints = $('#block-counter').waypoint({
        handler: function (direction) {
          CountAnim.init();
          this.destroy();
        },
        offset: 'bottom-in-view'
      });
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
  /*jshint +W003 */
})(jQuery);
